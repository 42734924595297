<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-form ref="form" :model="form" label-width="90px">
					<el-row :gutter="10">
						<el-col :span="6" :xs="24">
							<el-form-item label="日期">
								<el-date-picker v-model="form.valueData" type="daterange" value-format="YYYY-MM-DD"
									range-separator="至" start-placeholder="开始日期"
									end-placeholder="结束日期"></el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="userId"><el-input v-model="form.id" clearable placeholder="请输入userId"
									@clear="clearclick"></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="设备uuid"><el-input v-model="form.uuid" clearable
									placeholder="请输入设备uuid"></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="微信昵称"><el-input v-model="form.wxNickname" clearable
									placeholder="请输入微信昵称"></el-input></el-form-item>
						</el-col>
					</el-row>
					<el-row class="top_l">
						<el-col :span="24" :xs="24" class="el">
							<el-button type="primary" @click="jouShare">搜索</el-button>
							<div class="readbox">
								<el-tag size="large">用户状态：</el-tag>
								<el-radio-group v-model="form.status" @change="radioes">
									<el-radio :label="''" border>全部</el-radio>
									<el-radio :label="0" border>注销</el-radio>
									<el-radio :label="1" border>正常</el-radio>
									<el-radio :label="2" border>禁提现</el-radio>
								</el-radio-group>
							</div>
						</el-col>
					</el-row>
				</el-form>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
					style="width: 100%" border stripe>
					<el-table-column prop="id" label="userId" width="80" align="center"></el-table-column>
					<el-table-column prop="uuid" label="设备uuid" width="300"></el-table-column>
					<el-table-column prop="androidVersion" label="安卓版本" width="90" align="center"></el-table-column>
					<el-table-column prop="appVersion" label="app版本" width="90" align="center"></el-table-column>
					<el-table-column label="有无sim卡" width="100" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.hasSim == 0">无</span>
							<span v-else>有</span>
						</template>
					</el-table-column>
					<el-table-column prop="ip" label="ip地址" width="150" align="center"></el-table-column>
					<el-table-column prop="openId" label="微信openId" width="160" align="center"></el-table-column>
					<el-table-column prop="wxNickname" label="微信昵称" width="100" align="center"></el-table-column>
					<el-table-column label="微信头像" width="100">
						<template v-slot="scope">
							<img :src="scope.row.wxHeadimgUrl" style="width: 50px;display: block;margin:0 auto;" alt=""
								srcset="" />
						</template>
					</el-table-column>
					<el-table-column prop="level" label="等级" width="100" align="center"></el-table-column>
					<el-table-column label="用户状态" width="130" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.status == 0" style="color: red;">注销</span>
							<span v-if="scope.row.status == 1" style="color: green;">正常</span>
							<span v-if="scope.row.status == 2" style="color: blue;">禁提现</span>
							<!-- <el-switch
								@change="switchClick(scope.row)"
								v-model="scope.row.status"
								class="mb-2"
								style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
								:active-value="1"
								:inactive-value="0"
								active-text="是"
								inactive-text="否"
							/> -->
						</template>
					</el-table-column>
					<el-table-column prop="vipEndDay" label="vip到期日" width="100" align="center"></el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="150" align="center"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" width="150" align="center"></el-table-column>
					<el-table-column prop="lastLoginTime" label="最后一次登录时间" width="150" align="center"></el-table-column>
					<el-table-column label="操作" :width="width" fixed="right" align="center">
						<template v-slot="scope">
							<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
								<el-button v-if="item.parentId == twoId && item.permission.includes('push')"
									:key="index" size="small" type="primary" plain
									@click="update(item.permission.slice(5), scope.row, item)">
									{{ item.name }}
								</el-button>
							</template>
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">修改状态</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
						:total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</el-card>
			<!-- 修改-配置 -->
			<el-dialog v-model="dialogEdit" title="修改状态" width="30%" :fullscreen="fullscreenshow">
				<el-form :model="EditruleForm" ref="EditruleFormRef" label-width="110px" class="demo-ruleForm">
					<el-row :gutter="20">
						<el-col :span="24" :xs="24">
							<el-form-item label="用户状态">
								<el-select v-model="EditruleForm.status" placeholder="请选用户状态">
									<el-option :key="0" label="注销" :value="0" />
									<el-option :key="1" label="正常" :value="1" />
									<el-option :key="2" label="禁提现" :value="2" />
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" @click="EditsubmitForm">提交</el-button>
						<el-button @click="dialogEdit = false">取消</el-button>
					</span>
				</template>
			</el-dialog>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'userInfo',
	data() {
		return {
			fullscreenshow: false,
			dialogEdit: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			width: 350,
			form: {
				id: '',
				uuid: '',
				wxNickname: '',
				status: '',
				valueData: []
			},
			EditruleForm: {
				id: '',
				status: ''
			},

			twoId: null
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
			this.width = 'auto';
		} else {
			this.fullscreenshow = false;
			this.width = 350;
		}
		// 时间初始化
		const end = new Date();
		const start = new Date();
		start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
		this.form.valueData.push(this.$moment(start).format('YYYY-MM-DD'));
		this.form.valueData.push(this.$moment(end).format('YYYY-MM-DD'));

		// 二级目录ID
		this.twoId = this.$route.query.Id;
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		this.form.id = this.$TOOL.data.get('USERID');
		// 获取用户信息
		this.userInfo();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取用户信息
				this.userInfo();
			}
		},
		'$store.state.userid'(newval) {
			if (this.form.id != newval) {
				this.form.id = newval;
				// 获取用户信息
				this.userInfo();
			}
		}
	},
	methods: {
		// 获取用户信息
		userInfo() {
			this.serloading = true;
			this.$HTTP.post(
				'userInfo/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					id: this.form.id,
					uuid: this.form.uuid,
					wxNickname: this.form.wxNickname,
					status: this.form.status,
					startDay: this.form.valueData[0],
					endDay: this.form.valueData[1]
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			if (!this.form.valueData) {
				this.form.valueData = '';
			}
			// 获取用户信息
			this.userInfo();
		},
		//查询是否可用
		radioes() {
			this.currentPage = 1;
			this.userInfo();
		},
		clcedit(row) {
			this.EditruleForm.status = row.status;
			this.EditruleForm.id = row.id;
			this.dialogEdit = true;
		},
		EditsubmitForm() {
			this.$HTTP.post(
				'userInfo/updateStatus',
				{
					id: this.EditruleForm.id,
					status: this.EditruleForm.status
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.dialogEdit = false;
							// 获取配置
							this.userInfo();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 是否可用
		switchClick(row) {
			this.$HTTP.post(
				'userInfo/updateStatus',
				{
					id: row.id,
					status: row.status
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取配置
							this.userInfo();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 权限button按钮方法
		update(methodsNums, row, item) {
			//触发这个参数的方法
			this[methodsNums](row, item);
		},
		// 关联跳转
		push(row, item) {
			this.$store.commit('setuserid', row.id);
			this.$TOOL.data.set('USERID', row.id);

			this.$router.push({
				path: item.url.split('?')[0],
				query: {
					Id: item.url.split('=')[1]
				}
			});
		},
		pushtab(row, item) {
			this.$store.commit('setuserid', row.id);
			this.$TOOL.data.set('USERID', row.id);

			this.$router.push({
				path: item.url.split('?')[0],
				query: {
					Id: item.url.split('=')[1]
				}
			});
		},

		pushcleanvip(row) {
			this.$HTTP.post(
				'userInfo/clearUserVip',
				{
					id: row.id,
					productId: this.productId,
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取配置
							this.userInfo();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// input 清空
		clearclick() {
			this.$TOOL.data.remove('USERID');
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取用户信息
			this.userInfo();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取用户信息
			this.userInfo();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el {
	display: flex;
	align-items: center;
}

.readbox {
	display: flex;
	align-items: center;
	margin-left: 10px;

	span {
		margin-right: 10px;
		font-size: 16px;
	}
}
</style>
